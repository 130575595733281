<ng-container *ngIf="files.length > 0">
  <section 
    class="image-container" 
    cdkDropList 
    (cdkDropListDropped)="onDropDay($event)" 
    cdkScrollable >
    <div 
      class="img-box"
      cdkDrag 
      *ngFor="let file of files; let i = index">
      <div class="img-content">
        <ng-container *ngIf="!isImage(file)">
          <a [href]="file">
            <img [src]="getUploadedFileUrl(file)" class="document" alt="document" width="auto" height="100px" />
          </a>
        </ng-container>
        <ng-container *ngIf="isImage(file)">
          <p-image [src]="file" alt="Image" width="200px" height="100px" [preview]="true"></p-image>
        </ng-container>
        <span class="pi pi-times-circle close" (click)="deleteImage(i)"></span>
      </div>
    </div>
  </section>
</ng-container>

<div class="file-container" appDnd (fileDropped)="onFileDropped($event)">
  <input type="file" #fileDropRef id="fileDropRef" (change)="fileBrowseHandler($event)" multiple="multiple" />
  <i class="fa fa-upload upload-icon" aria-hidden="true"></i>
  <div class="select-file">
    <div class="select-file-title">Click To Upload</div>
    <div class="select-file-sub-title">or just drag and drop</div>
  </div>
</div>

<ng-container *ngIf="getSelectedFiles.length > 0">
  <div class="sub-title">
    <span>Selected Files</span>
    <div class="operation-wrapper">
      <div class="check-wrapper">
        <mat-checkbox color="warn" [(ngModel)]="selectAllFiles" (change)="onChangeCheckbox()">
        </mat-checkbox>
        <span (click)="toggleCheckBox()">Select All File</span>
      </div>
      <span class="pi pi-trash" (click)="onClickDelete()"></span>
    </div>
  </div>

  <div class="progress-container">
    <div class="progress" *ngFor="let item of getSelectedFiles; let i=index;" (click)="onSelected(i)"
      [ngClass]="{'selected': item?.selected, 'error': item.errorMessage !== ''}">
      <div class="progress-left">
        <img [src]="getFileUrl(item)" alt="file" loading="lazy" />
      </div>
      <div class="progress-details">
        <div class="details-left">
          <p>{{ splitFileName(item.file.name) }} </p>
          <p>
            <span *ngIf="item.progress <= 100 && !item.uploaded && !item.uploadingError" class="flex">
              {{convertKbToMb(item.loaded)}} / {{convertKbToMb(item.file.size)}} <span
                class="success-text">{{item.isCompressed ? '- Compressed' :
                ''}}</span>
              <span class="flex" *ngIf="item.isCompressing">
                -
                <div class="loader-wrapper">
                  <app-loader [size]="25">
                  </app-loader>
                </div>
                Compressing...
              </span>
            </span>
            <span *ngIf="item.progress <= 100 && !item.uploaded && !item.uploadingError">{{item.progress}}%</span>
          </p>
          <progress [value]="item.progress" max="100"
            *ngIf="item.progress <= 100 && !item.uploaded">0%</progress>
          <p *ngIf="item.uploaded || item.uploadingError">
            {{convertKbToMb(item.file.size)}} {{item.uploaded ? '- Uploaded' : ''}}
          </p>
          <div class="error-wrapper" *ngIf="item.errorMessage !== ''">
            <span>{{item.errorMessage}}</span>
          </div>
        </div>
        <div class="details-right" *ngIf="item.uploaded || item.uploadingError">
          <span class="pi pi-check" *ngIf="!item.uploadingError"></span>
          <span class="pi pi-info-circle error-icon" *ngIf="item.uploadingError"></span>
        </div>
      </div>
    </div>
  </div>

  <div class="save-wrapper">
    <button class="go-back" (click)="onClickCompressor()">
      <div class="loader-wrapper" *ngIf="isCompressing">
        <app-loader [size]="25">
        </app-loader>
      </div>
      <span>
        {{isCompressing ? 'Compressing...' : 'Compress All Images'}}
      </span>
    </button>
    <button class="save-and-exit" (click)="onClickUpload()">
      <div class="loader-wrapper" *ngIf="isUploading">
        <app-loader [size]="25">
        </app-loader>
      </div>
      <span>
        {{isUploading ? 'Uploading...' : 'Upload All Files'}}
      </span>
    </button>
  </div>
</ng-container>